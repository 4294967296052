body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Tahoma";
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

h1{
    position: absolute;
    top: 110px;
    left: 180px;
    opacity: 0.5;
    color: #ff0000;
}

h4{
    margin: 0;
    font-size: 10pt;
}

.page {
    width: 210mm;
    min-height: 297mm;
    padding: 10mm;
    margin: 10mm auto;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.content{
    width: 190mm;
    height: 277mm;
    position: relative;
}

table {
    border-collapse: collapse;
    width: 100%;
}

.header, .footer, .body{
    width: 100%;
}

.header, .footer{
    position: absolute;
    font-size: 14pt;
    font-weight: bold;
}

.header{
    top: 0;
    text-align: center;
}

.footer{
    bottom: 0;
}

.icon{
    display: inline-block;
    border: 5px #000 solid;
    padding: 13px 3px;
    border-radius: 40px;
}

.icon.left{
    float: left;
    margin-left: -5mm;
}

.icon.right{
    float: right;
    margin-right: -5mm;
}

.header .center{
    padding: 18px 0;
    display: inline-block;
}

.body{
    padding-top: 15mm;
    font-size: 10pt;
}

.section{
    margin: 4mm 0;
}

.grey{
    background-color: #eaecee;
    -webkit-print-color-adjust: exact !important;
    padding: 5px 1%;
}

.style-2 td{
    padding: 10px;
}

.style-3 tr > td:first-child,
.style-4 tr > td:first-child{
    width: 30%;
}

.style-4 tr > td:nth-child(2){
    width: 15%;
}

.border td{
    border: 1px #000 solid;
}

.style-1 tr th:first-child,
.style-1 tr td:first-child, 
.style-1 tr th:last-child,
.style-1 tr td:last-child{
    width: 35%;
}

.style-1 tr th:nth-child(2),
.style-1 tr td:nth-child(2),
.style-1 tr th:nth-child(3),
.style-1 tr td:nth-child(3),
.style-1 tr th:nth-child(4),
.style-1 tr td:nth-child(4){
    width: 10%;
} 

input.lines{
    width: 100%;
    border-bottom: 1px #000 solid;
    box-shadow: none;
    color: #000;
    background: transparent;
    padding: 3px;
    margin: 0;
}

.checkbox{
    padding: 0 10px;
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}