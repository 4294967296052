.opportunity-list{
	padding: 10px 20px;
    background: #f4f7f8;
    margin: 10px auto;
	padding: 20px;
	background: #f4f7f8;
	border-radius: 8px;
}

fieldset{
	border: none;
}

legend {
	font-size: 1.4em;
    margin-top: 50px;
    margin-bottom: 20px;
}

.number {
	background: #1abc9c;
	color: #fff;
	height: 30px;
	width: 30px;
	display: inline-block;
	font-size: 0.8em;
	margin-right: 4px;
	line-height: 30px;
	text-align: center;
	text-shadow: 0 1px 0 rgba(255,255,255,0.2);
	border-radius: 15px 15px 15px 0px;
}

.heading {
    position: relative;
    text-align: center;
    margin: 0px auto 20px auto;
    padding-bottom: 20px;
    font-size: 2rem;
    letter-spacing: 2px;
    margin: 2rem 0;
}

.heading:after{
    content : "";
    position: absolute;
    left: 50%;
    bottom  : 0;
    margin-right: -50%;
    transform: translate(-50%, 0);
    height  : 1px;
    width   : 400px;
    max-width: 80%;
    border-bottom: 1px #8a97a0 solid;
}

.heading--shadow{
    text-shadow: 2px 2px #1abc9c;
}

.heading--stroke {
    -webkit-text-stroke-width: 1px;
    -moz-text-stroke-width: 1px;
    -webkit-text-stroke-color: #47525d;
    -moz-text-stroke-color: #47525d;
    color: transparent;
}

input[type="submit"]:hover, input[type="button"]:hover
{
	background: #109177;
}

.opportunity-list{
    width: 90%;
}

.opportunity-list ul{
    list-style: none;
}

.opportunity-list table, .opportunity-list th, .opportunity-list td {
    border: 1px solid #d4d9dd;
}

.opportunity-list table{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.opportunity-list table tr th,
.opportunity-list table tr td{
    padding: 10px 5px;
}

.opportunity-list table tr th{
    width: 15%;
}

.opportunity-list table tr td textarea{
    background: var(--ck-color-base-background);
    color: #000;
    margin: 0;
    font-family: inherit;
    border: 1px var(--ck-color-base-border) solid;
    border-radius: 2px;
}

.opportunity-list #align-button{
    width: 200px;
}
