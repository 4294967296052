.search-form{
    max-width: 500px;
	padding: 10px 20px;
    background: #f4f7f8;
    margin: 10px auto;
	padding: 20px;
	background: #f4f7f8;
	border-radius: 8px;
}

fieldset{
	border: none;
}


.heading {
    position: relative;
    text-align: center;
    margin: 0px auto 20px auto;
    padding-bottom: 20px;
    font-size: 2rem;
    letter-spacing: 2px;
    margin: 2rem 0;
}

.heading:after{
    content : "";
    position: absolute;
    left: 50%;
    bottom  : 0;
    margin-right: -50%;
    transform: translate(-50%, 0);
    height  : 1px;
    width   : 400px;
    max-width: 80%;
    border-bottom: 1px #8a97a0 solid;
}

.heading--shadow{
    text-shadow: 2px 2px #1abc9c;
}

.heading--stroke {
    -webkit-text-stroke-width: 1px;
    -moz-text-stroke-width: 1px;
    -webkit-text-stroke-color: #47525d;
    -moz-text-stroke-color: #47525d;
    color: transparent;
}

label {
	display: block;
	margin-bottom: 8px;
}

input[type="text"], input[type="date"], input[type="datetime"], input[type="email"],
input[type="number"], input[type="search"], input[type="time"], input[type="url"],
textarea, select {
	font-family: Georgia, "Times New Roman", Times, serif;
	background: rgba(255,255,255,.1);
	border: none;
	border-radius: 4px;
	font-size: 15px;
	margin: 0;
	outline: 0;
	padding: 10px;
	width: 100%;
	box-sizing: border-box; 
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box; 
	background-color: #e8eeef;
	color:#8a97a0;
	-webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
	box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
	margin-bottom: 30px;
}

input[type="text"]:focus, input[type="date"]:focus, input[type="datetime"]:focus,
input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus,
input[type="time"]:focus, input[type="url"]:focus, textarea:focus, select:focus{
	background: #d2d9dd;
}

select{
	-webkit-appearance: menulist-button;
	height:35px;
}

input[type="submit"], input[type="button"]
{
	position: relative;
	display: block;
	padding: 19px 39px 18px 39px;
	color: #FFF;
	margin: 0 auto;
	background: #1abc9c;
	font-size: 18px;
	text-align: center;
	font-style: normal;
	width: 100%;
	border: 1px solid #16a085;
	border-width: 1px 1px 3px;
	margin-bottom: 10px;
}

input[type="submit"]:hover, input[type="button"]:hover
{
	background: #109177;
}
